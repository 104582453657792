import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EditProfileSuccessChangeComponent } from '../../dialog/edit-profile-success-change/edit-profile-success-change.component';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/core/models/user.model';
import { StorageService } from 'src/app/core/services/storage.service';

import { v4 as uuid } from 'uuid';
import { PiiService } from 'src/app/core/services/pii.service';
import { Pii } from 'src/app/core/models/pii.model';
import { InputTelephoneComponent } from 'src/app/shared/components/simple/input-telephone/input-telephone.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import {
  Question,
  QuestionType,
} from 'src/app/core/models/questionnaire.model';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  user: User | null = null;
  form: FormGroup = new FormGroup({});
  phoneNumberCountryPrefix = '+55';

  languageOptions = [
    { value: 'PT', text: 'Português (BR)' },
    { value: 'EN', text: 'English' },
  ];

  @ViewChild('phoneinput') phoneInput!: NgxMatIntlTelInputComponent;

  addressQuestion: Question = {
    configuration: {},
    created_at: new Date(),
    id: '',
    name: 'Address',
    questionnaire_id: '',
    response_type: QuestionType.ADDRESS,
    slug: '',
    statement: '',
  };

  pii?: Pii;

  profileImg: string | null = null;
  profileName = '-';
  profileLoading = false;
  // TODO: Implement the user data

  name: string = '';

  language = 'EN';

  streetNumber = '';
  address = '';
  complement = '';
  city = '';
  zipcode = '';
  countryState = '';

  preferredCountries = ['gb', 'us'];

  constructor(
    public dialog: MatDialog,
    private us: UserService,
    private ss: StorageService,
    private piis: PiiService
  ) {}

  async ngOnInit(): Promise<void> {
    const user = await this.us.getCurrentUser();
    this.user = user;
    this.buildProfile();
    console.log('user', user);

    if (!user || !user.primary_email) {
      console.error('No user loaded', user);
      return;
    }

    this.language = user.language || 'EN';

    const pii = await this.piis.getFromUserId(user.id!);
    this.pii = pii;
    if (!this.pii.attributes) {
      const displayName = user.display_name || '';
      const nameParts = displayName.split(' ').filter(Boolean); // Filter removes empty strings
      const fname = nameParts[0] || '';
      const lname = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
      const middleNames =
        nameParts.length > 2 ? nameParts.slice(1, -1).join(' ') : ''; // Joins all middle names

      this.pii.attributes = {
        first_name: fname,
        last_name: lname,
        middle_name: middleNames,
      };
    }

    if (!this.pii.contacts) {
      console.log('[PROFILE] Did not have contacts', this.pii);
      this.pii.contacts = {
        phone: '',
        email: user.primary_email,
      };
    }

    console.log('this.user', this.user);
    console.log('this.pii', this.pii);
  }

  changePhone(ev: any) {
    console.log('[PROFILE] changePhone', { ev, pii: this.pii });
  }

  buildProfile() {
    this.us.getCurrentUser().then((u) => {
      // Ckech user is logged

      console.log('User', u);

      if (u && u.avatar_url) {
        const imgUrl = `profile-pictures/${u.id}/${u.avatar_url}`;
        const bucket = 'profile-pictures';
        const path = `${u.id}/${u.avatar_url}`;

        console.log('Image URL', imgUrl);

        console.log('Bucket', bucket);
        console.log('Path', path);
        this.ss
          .signedUrl(bucket, path)
          .then((url) => {
            console.log('URL', url);
            if (url.error) {
              console.error('Error getting URL', url.error);
              return;
            }

            if (!url.data) {
              console.error('Error getting URL', url);
              return;
            }

            if (!url.data.signedUrl) {
              console.error('Error getting URL', url);
              return;
            }

            this.profileImg = url.data.signedUrl;
          })
          .catch((err) => {
            console.error('Error getting URL', err);
          });

        return;
      }

      this.profileImg = null;
      if (!u || !u.display_name) {
        this.profileName = '-';
      } else {
        // Get the First Letter by each word splitted by space and limit to 2.
        // Always get the the first and the last letter
        // Example: John Doe Mark => JM

        if (!u) {
          this.profileName = '-';
          return;
        }

        if (!u.display_name) {
          this.profileName = '-';
          return;
        }

        if (!u.display_name.trim()) {
          this.profileName = '-';
          return;
        }
        if (u.display_name === 'undefined') {
          this.profileName = '-';
          return;
        }

        const nameSplits = u.display_name.split(' ');
        const firstName = nameSplits[0];
        const lastName =
          nameSplits.length > 1 ? nameSplits[nameSplits.length - 1] : null;

        const firstLetter = firstName[0];
        const lastLetter = lastName ? lastName[0] : '';

        this.profileName = `${firstLetter}${lastLetter}`;
      }
      console.log('Profile Text', this.profileName);
    });
  }

  async changeProfileImg() {
    try {
      if (this.profileImg) {
        // TODO: Remove Image form bucket
        const bucket = 'profile-pictures';
        const path = `${this.user!.id}/${this.user!.avatar_url}`;

        await this.ss.DeleteFilesInBucket(bucket, [path]);
        // TODO: Remove Image path from user
        await this.us.update({ ...this.user, avatar_url: null } as any);
        this.buildProfile();
      } else {
        // Pick new image
        const pickFile = document.createElement('input');
        pickFile.type = 'file';
        pickFile.accept = 'image/*';
        pickFile.click();

        pickFile.onchange = async () => {
          const file = pickFile.files?.[0];
          if (!file) return;

          console.log('File', file);
          const ext = file.name.split('.').pop();
          // Save on storage

          const bucket = 'profile-pictures';
          const imageID = `${uuid()}.${ext}`;

          console.log('Bucket', bucket);
          console.log('Image ID', imageID);
          const path = `${this.user!.id}/${imageID}`;

          const { data: img, error } = await this.ss.upload(bucket, path, file);

          if (error) {
            console.error('Error saving image', error);
            throw error;
          }
          // Save Path on user
          await this.us.update({ ...this.user, avatar_url: imageID });

          this.buildProfile();
        };
      }
    } catch (error) {
      console.error('Error changing profile image', error);
      return;
    }
  }

  async handleSubmit() {
    if (!this.pii) {
      throw new Error('[PROFILE] PII MISSING');
    }

    try {
      const dn = `${this.pii.attributes.first_name || ''} ${
        this.pii.attributes.last_name || ''
      }`
        .trim()
        .replace(/\s{2,}/g, ' ');

      const nuser: Partial<User> = {
        ...this.user,
        display_name: dn,
        language: this.language,
      };

      const ln = `${this.pii.attributes.first_name || ''} ${
        this.pii.attributes.middle_name || ''
      } ${this.pii.attributes.last_name || ''}`.trim();
      const npii: Partial<Pii> = {
        ...this.pii,
        legal_name: ln.trim().replace(/\s{2,}/g, ' '),
      };

      delete npii.search_vector;
      console.log('[PROFILE] Pre save', { nuser, npii });

      console.log('saving nuser', nuser);
      const res = await this.us.update(nuser);
      const rpii = await this.piis.update(npii);

      this.openDialog();
    } catch (error) {
      console.error(error);
    }
  }

  private openDialog() {
    const ref = this.dialog.open(EditProfileSuccessChangeComponent);
    return ref;
  }
}
