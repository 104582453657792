import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SignupComponent } from './pages/signup/signup.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { OneTimeLinkComponent } from './pages/one-time-link/one-time-link.component';
import { LanguageGuard } from 'src/app/core/guards/language.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'forgot', component: ForgotPasswordComponent },
      { path: 'recovery', component: ForgotPasswordComponent },
      { path: 'confirm', component: VerifyComponent },
      { path: 'otl', component: OneTimeLinkComponent },
      { path: '**', redirectTo: '/login', pathMatch: 'full' },
    ],
    canActivate: [LanguageGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RootRoutingModule {}
